<template>
  <div :class="el">
    <img
      :class="`${el}__logo`"
      src="@/assets/logo-curabox.png"
      srcset="@/assets/logo-curabox.png 1x, @/assets/logo-curabox@2x.png 2x"
      alt="curabox logo"
      @click="onLogoClick()"
    />
    <span
      v-if="false"
      class="icon icon-settings-20px"
      @click="router.push('/settings')"
    />
    <!--        <span class="icon icon-phone-20px" @click="$router.push('/contact')"/>-->
    <div class="icon icon-profile-30px"></div>
    <div :class="`${el}__username`">
      {{ store.user.first_name }} {{ store.user.last_name }}
    </div>

    <div :class="[`${el}__menu`, { [`${el}__menu--open`]: isMenuOpen }]">
      <div class="icon icon-chevron-down-20px" @click="toggleMenu()"></div>
      <div
        :class="[
          `${el}__menu__content`,
          { [`${el}__menu__content--visible`]: isMenuOpen },
        ]"
      >
        <span :class="`${el}__menu__content__link`" @click="logout"
          >Logout</span
        >
        <span :class="`${el}__menu__content__link`" @click="goToProfile"
          >Mein Profil</span
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, ref } from 'vue';
import { useStore } from '@/store/index';
import { useRouter, useRoute } from 'vue-router';

const el = 'header';

const store = useStore();
const router = useRouter();
const route = useRoute();

const apiService = inject('apiService');
const showHint = inject('showHint');

const isMenuOpen = ref(false);

function toggleMenu() {
  isMenuOpen.value = !isMenuOpen.value;
}

function onLogoClick() {
  if (route.name === 'Boxes') {
    router.go(0);
  } else {
    router.push('/boxes');
  }
}

async function logout() {
  const response = await apiService.logout();
  if (response) {
    store.isLoggedIn = false;
    await router.push('/login');
    showHint({ id: 'logout', data: null });
  }
}

function goToProfile() {
  isMenuOpen.value = false;
  router.push('/profile');
}
</script>
