import { createApp } from 'vue';
import { createPinia } from 'pinia';

import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import smoothscroll from 'smoothscroll-polyfill';
import KProgress from 'k-progress-v3';

import 'core-js/stable';

import Tracking from 'shared-components/src/classes/Tracking';
import ApiService from './services/ApiService';

import App from './App.vue';
import router from './router';
import './styles/styles.scss';

/** ***********
 * Import Components
 **************/

/** ***
 * Shared Components
 ******/
import {
  BaseDate,
  BaseButton,
  BaseInput,
  BaseCheckbox,
  BaseRadio,
  BaseSelect,
  BaseAutocomplete,
  BaseClickBox,
  BaseTextArea,
  cbOptPhoneInput,
  cbInfoTooltip,
  cbTerminationHint,
  cbHintBox,
  cbAgbLayer,
  cbTerminationReasons,
} from 'shared-components';

/** ***
 * Professional Components
 ******/
// import BoxChangeHint from '@/components/BoxChangeHint.vue';
import BoxCase from '@/components/BoxCase.vue';
import BoxesPatientsMenu from '@/components/BoxesPatientsMenu.vue';
import BoxHeader from '@/components/BoxHeader.vue';
import BoxHistoryTable from '@/components/BoxHistoryTable.vue';
import BoxRow from '@/components/BoxRow.vue';
import BoxProgressbar from '@/components/BoxProgressbar.vue';
import BoxSelection from '@/components/BoxSelection.vue';
import CaseDataBox from '@/components/CaseDataBox.vue';
import CaseDataDelivery from '@/components/CaseDataDelivery.vue';
import CaseDataGloves from '@/components/CaseDataGloves.vue';
import CasesHint from '@/components/CasesHint.vue';
import cbBreadcrumb from '@/components/cbBreadcrumb.vue';
import cbHint from '@/components/cbHint.vue';
import cbPagination from '@/components/cbPagination.vue';
import DeliveryRow from '@/components/DeliveryRow.vue';
import DownloadFlyout from '@/components/DownloadFlyout.vue';
import IconLink from '@/components/IconLink.vue';
import LoginForm from '@/components/LoginForm.vue';
import PG51Row from '@/components/PG51Row.vue';
import ProductRow from '@/components/ProductRow.vue';
import ProfileEditForm from '@/components/ProfileEditForm.vue';
import SettingsUserEdit from '@/components/SettingsUserEdit.vue';
import SettingsUserTable from '@/components/SettingsUserTable.vue';
import ViewHeadline from '@/components/ViewHeadline.vue';
import GloveOptions from '@/components/GloveOptions.vue';

/** ***********
 * Constants
 **************/

const apiService = new ApiService();
const app = createApp(App);
const store = createPinia();

/** ***********
 * Sentry
 **************/

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    environment: import.meta.env.VITE_APP_SENTRY_ENVIRONMENT,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    logErrors: true,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracesSampleRate: 0,
  });
}

/** ***********
 * Provides
 **************/

app.provide(
  'baseImagePath',
  import.meta.env.APP_ENV === 'development' ? './' : 'src',
);
app.provide('apiService', apiService);
app.provide('window', window);
app.provide('track', (event, label) => {
  Tracking.trackDatalayer({
    category: 'professional.curabox.de',
    event,
    label: `curabox-${label}`,
  });
});
smoothscroll.polyfill();

/** ***********
 * Component
 **************/

app.component('BaseAutocomplete', BaseAutocomplete);
app.component('BaseButton', BaseButton);
app.component('BaseCheckbox', BaseCheckbox);
app.component('BaseClickBox', BaseClickBox);
app.component('BaseDate', BaseDate);
app.component('BaseInput', BaseInput);
app.component('BaseRadio', BaseRadio);
app.component('BaseSelect', BaseSelect);
app.component('BaseTextArea', BaseTextArea);
app.component('BoxCase', BoxCase);
// app.component('BoxChangeHint', BoxChangeHint);
app.component('BoxesPatientsMenu', BoxesPatientsMenu);
app.component('BoxHeader', BoxHeader);
app.component('BoxHistoryTable', BoxHistoryTable);
app.component('BoxRow', BoxRow);
app.component('BoxProgressbar', BoxProgressbar);
app.component('BoxSelection', BoxSelection);
app.component('CaseDataBox', CaseDataBox);
app.component('CaseDataDelivery', CaseDataDelivery);
app.component('CaseDataGloves', CaseDataGloves);
app.component('CasesHint', CasesHint);
app.component('cbAgbLayer', cbAgbLayer);
app.component('cbBreadcrumb', cbBreadcrumb);
app.component('cbHint', cbHint);
app.component('cbHintBox', cbHintBox);
app.component('cbInfoTooltip', cbInfoTooltip);
app.component('cbPagination', cbPagination);
app.component('cbTerminationHint', cbTerminationHint);
app.component('DeliveryRow', DeliveryRow);
app.component('DownloadFlyout', DownloadFlyout);
app.component('IconLink', IconLink);
app.component('LoginForm', LoginForm);
app.component('OptPhoneInput', cbOptPhoneInput);
app.component('PG51Row', PG51Row);
app.component('ProductRow', ProductRow);
app.component('ProfileEditForm', ProfileEditForm);
app.component('SettingsUserEdit', SettingsUserEdit);
app.component('SettingsUserTable', SettingsUserTable);
app.component('ViewHeadline', ViewHeadline);
app.component('GloveOptions', GloveOptions);

app.component('k-progress', KProgress);
app.component('cbTerminationReasons', cbTerminationReasons);

/** ***********
 * Uses
 **************/

app.use(store);
app.use(router);
store.use(() => ({ apiService }));

/** ***********
 * Mount
 **************/
app.mount('#app');
