import { createRouter, createWebHistory } from 'vue-router';

import Login from '@/views/Login.vue';
import Registration from '@/views/Registration.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import Boxes from '@/views/Boxes.vue';
import BoxDetails from '@/views/BoxDetails.vue';
import BoxEdit from '@/views/BoxEdit.vue';
import BoxDelivery from '@/views/BoxDelivery.vue';
import Cases from '@/views/Cases.vue';
import Profile from '@/views/Profile.vue';
import ProfileEdit from '@/views/ProfileEdit.vue';
// import Settings from '@/views/Settings.vue';
import ErrorPage from '@/views/ErrorPage.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      type: 'authentication',
    },
    {
      path: '/registrierung',
      name: 'Registration',
      component: Registration,
      type: 'authentication',
    },
    {
      path: '/passwort-vergessen',
      name: 'Forgot Password',
      component: ForgotPassword,
      type: 'authentication',
    },
    {
      path: '/boxes',
      name: 'Boxes',
      component: Boxes,
      title: 'Übersicht',
      meta: {
        permission: 'edit-curabox',
      },
    },

    // route disabled for MVP

    // {
    //   path: '/settings',
    //   name: 'Settings',
    //   component: Settings,
    //   title: 'Einstellungen',
    //   meta: {
    //     permission: 'edit-user',
    //   },
    //   children: [
    //     {
    //       path: '/settings/user',
    //       component: Settings,
    //       children: [
    //         {
    //           path: '/settings/user/edit/:id',
    //           name: 'Settings Edit',
    //           component: Settings,
    //           title: 'Nutzerdaten anpassen',
    //         },
    //         {
    //           path: '/settings/user/add',
    //           name: 'Settings add',
    //           component: Settings,
    //           title: 'neuen Nutzer anlegen',
    //         },
    //       ],
    //     },
    //   ],
    // },

    {
      path: '/box/:id/edit',
      name: 'BoxEdit',
      component: BoxEdit,
      title: 'Boxzusammenstellung ändern',
      meta: {
        permission: 'edit-curabox',
      },
    },
    {
      path: '/box/:id/delivery',
      name: 'BoxDelivery',
      component: BoxDelivery,
      title: 'Lieferbedingungen anpassen',
      meta: {
        permission: 'edit-curabox',
      },
    },
    {
      path: '/box/:id',
      name: 'BoxDetails',
      component: BoxDetails,
      title: 'Kundenprofil',
      meta: {
        permission: 'edit-curabox',
      },
    },
    {
      path: '/cases',
      name: 'Cases',
      component: Cases,
      title: 'Änderungsübersicht',
      meta: {
        permission: 'edit-curabox',
      },
    },
    {
      path: '/profile',
      name: 'Profile',
      component: Profile,
      title: 'Mein Profil',
    },
    {
      path: '/profile/edit',
      name: 'ProfileEdit',
      component: ProfileEdit,
      title: 'Profil ändern',
      meta: {
        permission: 'edit-profile',
      },
    },
    {
      path: '/:patchMatch(.*)* ',
      name: 'Error',
      component: ErrorPage,
      title: '404',
    },
    {
      path: '/',
      redirect: {
        name: 'Login',
      },
    },
  ],
  scrollBehavior() {
    return { top: 0 };
  },
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

router.beforeEach((to, from, next) => {
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    router.push({ name: to.name, query: from.query });
  } else {
    next();
  }
});

export default router;
