<template>
  <div class="authentication flex-row">
    <LoginForm class="authentication__form" />
  </div>
</template>

<script setup>
import { onMounted } from 'vue';

import { useStore } from '@/store/index';
import { useRouter } from 'vue-router';

const router = useRouter();
const store = useStore();

onMounted(() => {
  if (store.isLoggedIn) {
    router.push('/boxes');
  }
});
</script>
